<!--
 * @Author: your name
 * @Date: 2021-03-03 16:04:22
 * @LastEditTime: 2023-02-13 12:00:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lsz-user-mp\src\views\appointment\index.vue
-->
<template>
  <div class="appointment">
    <van-form
      colon
      label-width="100"
      input-align="right"
      validate-trigger="onChange"
      @submit="onSubmit"
    >
      <!--      <patient-select @onConfirm="onConfirm"/>-->

      <van-field
        class="star"
        :value="patientName"
        is-link
        label="就诊人信息"
        name="patxm"
        readonly
        placeholder="选择就诊人"
        @click="showPicker"
      />
      <van-field
        v-model="cardName"
        is-link
        class="star"
        label="就诊卡 "
        name="cardName"
        readonly
        placeholder="请选择就诊卡号类型"
        :rules="[{ required: true }]"
        @click="showPiceker2"
      />
      <van-popup v-model="Picker2" position="bottom">
        <van-picker
          show-toolbar
          :columns="checkTypeList"
          @confirm="checkConfirm"
          @cancel="Picker2 = false"
        />
      </van-popup>
      <div class="fromTtem">
        <div>就诊类型</div>
        <div>{{ hylxmc }}</div>
      </div>
      <div class="fromTtem">
        <div>科室</div>
        <div>{{ ksmc }}</div>
      </div>
      <div class="fromTtem">
        <div>就诊日期</div>
        <div>{{ pbrq }}{{ sxwbzmc }}</div>
      </div>
      <div class="fromTtem fromTtem__tips">
        <div>挂号费</div>
        <div class="right">
          <div class="price">
            ¥{{ ghf }}
          </div>
          <div class="message">
            请于就诊当日进行支付
          </div>
        </div>
      </div>
      <van-field
        v-model="pushList[0].zdmc"
        is-link
        class="star"
        label="确诊疾病"
        name="illName"
        readonly
        placeholder=""
        :rules="[{ required: true }]"
        @click="showIllPop"
      />
      <label class="form-label star">病情主诉 :</label>
      <van-field
        v-model="remark"
        name="remark"
        rows="5"
        autosize
        :rules="[{ required: true }]"
        input-align="left"
        type="textarea"
        maxlength="200"
        placeholder="为了让医生更清楚的知道您的病情，请尽可能详细描述"
        show-word-limit
      />
      <div class="uppic">
        <div class="uptitle">
          上传检查检验报告和患处照片（最多9张）
        </div>
        <van-uploader
          v-model="fileList"
          multiple
          :max-size="10 * 1024 * 1024"
          max-count="9"
          preview-size="106"
        />
      </div>
      <div class="footBtn">
        <van-button
          round
          size="small"
          type="info"
          class="btn"
          native-type="submit"
          :loading="buttonLoading"
          :disabled="disabled"
        >
          提交
        </van-button>
      </div>
    </van-form>
    <van-popup
      v-model="show"
      position="bottom"
      closeable
      lock-scroll
      :style="{ height: '50%', width: '100%' }"
      @cancel="show = false"
    >
      <div class="pop-title">
        近期确诊疾病
      </div>
      <van-cell
        v-for="(item, i) in illList"
        :key="i"
        size="large"
        :title="item.zdmc"
        @click="chooseItem(item)"
      />
    </van-popup>
    <Prompt
      :type-staus="typeStaus"
      :pbrq="pbrq"
      :sxwbzmc="sxwbzmc"
      :new-staus="newStaus"
      :toast-context="toastContext"
      @closePopup="closePopup"
    />
  </div>
</template>
<script>
//import DiseaseItem from '../../components/category/diseaseItem'
import Prompt from "../../components/prompt/Prompt"
import { appointModel } from "../../api/appoint"
import { constantModel } from "../../api/constant"
import { mapGetters } from "vuex"
import { isEmptyObject } from "@/utils/index"
// import {getItem, removeItem} from "@/utils/cache"
export default {
  components: {
    Prompt
  },
  data() {
    return {
      patient: { patxm: "" },
      show: false,
      showPop: true,
      value: "",
      Picker2: false,
      showIlless: false,
      newshowIlless: false,
      index: 0,
      loading: false,
      disabled: false,
      buttonLoading: false,
      finished: false,
      checkTypeList: [],
      patientList: {},
      typeStaus: false,
      newStaus: true,
      toastContext: "",
      cardName: "",
      hylxmc: "",
      ksmc: "",
      ksdm: "",
      pbrq: "",
      sxwbzmc: "",
      jzkh: "", //就诊卡号
      jzklx: "", //就诊卡号类型
      illName: "", //选择的疾病名称
      ghf: "",
      id: "",
      remark: "",
      form: {
        userPatientId: "", //就诊人id
        cardNumber: "",
        diseaseIds: [], //疾病
        remark: "",
        fileList: [] //图片
      },
      fileList: [],
      pushList: [{}],
      chooseId: "",
      zdmcOrsp: "",
      illList: [],
      total: 0,
      count: 10,
      page: 1,
      pageSzie: 10
    }
  },
  computed: {
    patientName() {
      // console.log('patient change', this.patientList)
      const { zjh } = this.patientList
      if (zjh) {
        this.getIllList(zjh, this.ksdm)
      }
      return this.patientList ? this.patientList.patxm : ""
    },
    ...mapGetters({ choosePatientList: "patient/getChoosePatient" })
  },
  mounted() {
    const { hylx, ksmc, pbrq, sxwbzmc, ghf, id, ksdm } = this.$route.query
    this.hylxmc = hylx
    this.ksmc = ksmc
    this.pbrq = pbrq
    this.sxwbzmc = sxwbzmc
    this.ghf = ghf
    this.id = id
    this.ksdm = ksdm
    this.onConfirm()
  },
  methods: {
    showPicker() {
      this.$router.push({
        path: "/patient",
        query: {
          appoint: true
        }
      })
    },
    showPiceker2() {
      if (this.checkTypeList.length <= 0) {
        this.$dialog
          .confirm({
            message:
              "当前患者未绑定医保卡或者就诊卡，请先进行绑定才可以进行挂号!"
          })
          .then(async () => {
            this.showPicker()
          })
        return
      }

      let array = this.checkTypeList.filter((item) => {
        return item.jzkh == ""
      })
      if (array.length >= 2) {
        this.$dialog
          .confirm({
            message:
              "当前患者未绑定医保卡或者就诊卡，请先进行绑定才可以进行挂号!"
          })
          .then(async () => {
            this.showPicker()
          })
        return
      }
      this.Picker2 = true
    },
    showIllPop() {
      if (this.illList.length <= 0) return
      this.show = true
    },
    closePopup() {
      this.typeStaus = false
    },
    onLoad() {
      this.loading = true
      this.page += 1
    },
    chooseItem(item) {
      this.pushList[0] = item
      this.show = false
    },
    onSearch() {
      this.illList = []
      this.page = 1
      this.total = 0
      this.getIllList()
    },
    getIllList(zjh, ksdm) {
      let params = {
        zjhm: zjh,
        deptCode: ksdm
      }
      appointModel.patientvisitrecords(params).then((res) => {
        if (!res.data) {
          this.showIlless = true
          this.typeStaus = true
          this.newStaus = false
          this.disabled = true
          this.toastContext =
            "不符合在线复诊条件，请前往线下医院就诊！\n 不满足的原因可能是：\n 1、2个月内未在本院就诊\n 2、该疾病未有初诊记录"
        } else {
          this.newshowIlless = true
          if (res.data.length > 1) {
            this.newshowIlless = false
          }
          res.data.forEach((item) => {
            item.zdbm = item.zddm
          })
          this.pushList = [
            {
              zdbm: res.data[0].zddm,
              zdmc: res.data[0].zdmc
            }
          ]
        }
        this.illList.push(...res.data)
      })
    },
    onConfirm() {
      // console.log(isEmptyObject(this.choosePatientList), "选择")
      if (!isEmptyObject(this.choosePatientList)) {
        this.patientList = this.choosePatientList
        appointModel
          .patientCard({ id: this.choosePatientList.id })
          .then((res) => {
            const data = res.data.visitcardinfos
            if (data) {
              let arr = []
              for (let i = 0; i < data.length; i++) {
                let obj = {}
                if (data[i].jzkh != "") {
                  (obj.text = data[i].jzklxmc),
                    (obj.jzklx = data[i].jzklx),
                    (obj.jzkh = data[i].jzkh)
                  arr.push(obj)
                }
              }
              this.checkTypeList = arr
            }
          })
      }
    },
    add() {
      if (this.pushList.length == this.illList.length) {
        this.$toast("最多选择这些!")
        return false
      }
      // if(isEmptyObject(this.pushList[0])){
      //   this.$toast('请选择疾病')
      // }else{
      //   this.pushList.push({})
      // }
      this.pushList.push({})
      // console.log(this.pushList,"添加")
    },

    addValue(index) {
      // console.log(index,'index')
      this.chooseId = index
      this.show = true
    },
    delet(index) {
      this.pushList.splice(index, 1)
    },
    checkConfirm(val) {
      this.jzklx = val.jzklx
      this.jzkh = val.jzkh
      this.cardName = val.text
      this.Picker2 = false
    },
    async upload() {
      this.buttonLoading = true
      for (let i = 0; i < this.fileList.length; i++) {
        const { file } = this.fileList[i]
        const { data } = await constantModel.upImage(file)
        this.form.fileList.push(data && data.accessUrl)
      }
    },
    async submit() {
      if (isEmptyObject(this.pushList[0])) {
        this.$toast("请选择疾病")
      } else {
        let params = {
          bqzs: this.remark,
          diagnosis: this.pushList,
          ghf: this.ghf,
          hyid: this.id,
          jzklx: this.jzklx,
          jzrkh: this.jzkh,
          jzrxm: this.patientList.patxm
            ? this.patientList.patxm
            : this.patientList.text,
          zjhm: this.patientList.zjh,
          zjlx: this.patientList.zjlx,
          pictures: this.form.fileList
        }
        appointModel
          .appoint(params)
          .then((res) => {
            this.$router.push({
              path: "/appointment/detail",
              query: {
                id: res.data.appointId,
                type: "1"
              }
            })
          })
          .catch((e) => {
            if (e.code === 111101) {
              this.typeStaus = true
              this.toastContext = " 已有该医生的预约记录，不得重复预约！"
            } else if (e.code === 111108) {
              this.typeStaus = true
              this.toastContext = "当前时段号源无余量，请预约其他日期！"
            } else if (e.code === 111109) {
              this.typeStaus = true
              this.toastContext = "当前号源不在预约时间范围，请预约其他日期！"
            } else if (e.code === 111110) {
              this.typeStaus = true
              this.toastContext = e.data.message.replace(/↵/g, "\n")
            } else if (e.code === 111111) {
              this.typeStaus = true
              this.newStaus = false
              this.toastContext =
                "不符合在线复诊条件，请前往线下医院就诊！\n 不满足的原因可能是：\n 1、2个月内未在本院就诊\n 2、该疾病未有初诊记录"
            }
          })
      }
    },
    async onSubmit(value) {
      this.form.fileList = []
      await this.upload()
      this.buttonLoading = false
      // console.log(this.form.fileList,"图片文字")
      await this.submit(value)
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
.payment-tips-top {
  font-size: 26px;
  text-align: left;
  width: 686px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 12px auto 0;
  border-radius: 12px;
  color: #f56c6c;
  background: #fef0f0;
  line-height: 50px;
  height: 100px;
  border: 1px solid #ffe6e6;
}
.payment-tips {
  text-align: right;
  width: 686px;
  box-sizing: border-box;
  margin: 12px auto 0;
  border-radius: 12px;
  color: #f56c6c;
  font-size: 24px;
}
.star:before {
  content: "* ";
  color: red;
  position: absolute;
  left: 7px;
}
/deep/ .cellStyle {
  background: none;
}

/deep/ .van-cell__title,
.van-cell__value,
.van-cell__right-icon {
  color: inherit;
  font-size: 28px;
}

/deep/ .van-cell__value {
  color: #969799;
}

/deep/ .van-cell__right-icon {
  color: #969799;
}

/deep/ .van-cell {
  padding: 32px;
}

.appointment {
  height: 100%;
  overflow: scroll;
  font-size: @font-size-28;

  .appoint-input {
    padding: 32px 32px;
    display: flex;
    align-items: center;
  }

  .fromTtem {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 32px 32px;
    border-bottom: 1px solid #eeeeee;
    font-size: @font-size-28;
    color: @dark-font-color;
    background: #fff;
    &.fromTtem__tips {
      padding-bottom: 10px;
    }
    .choose {
      display: flex;
      align-items: center;

      .img {
        margin: 0 auto;
        width: 24px;
        height: 24px;
      }

      .add {
        margin-right: 10px;
      }
    }

    .right {
      text-align: right;

      .price {
        color: @color-E23E23;
      }

      .message {
        margin-top: 11px;
        color: @light-font-color;
        font-size: @font-size-24;
      }
    }
  }

  .form-label {
    display: block;
    padding: 32px 32px 0;
    color: #646566;
    font-size: @font-size-28;
    background: @white-color;
    margin-top: 16px;
  }

  .uppic {
    font-size: @font-size-30;
    color: @dark-font-color;
    background: @white-color;
    padding: 32px 32px;
    padding-bottom: 190px;

    .uptitle {
      margin-bottom: 32px;
    }
  }

  .footBtn {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 96px;
    line-height: 75px;
    text-align: center;
    background-color: @white-color;

    .btn {
      width: 90%;
      height: 68px;
    }
  }

  .search-content {
    height: 80%;
    overflow-y: scroll;
  }
  .pop-title {
    margin-top: 35px;
    text-align: center;
    font-size: @font-size-30;
  }
}
</style>
